import React, { useState, useEffect } from 'react';
import { MdTaskAlt, MdHome, MdLogin, MdLogout, MdSettings, MdWebStories } from 'react-icons/md';
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import { Button } from 'reactstrap';
import { getUser, Logout as HandleLogout } from '../../actions';
import { useSelector } from 'react-redux';
import './header.css';


function Header() {
 
  const [ working, setWorking ] = useState( false );
  const user = useSelector( state => state.user );
  const navigate = useNavigate();
  const location = useLocation();


  // User does not have an org associated with it
  if( user.id !== -1 && user.orgId === -1 && location.pathname !== '/orgs/create' ) navigate( '/orgs/create' );


  useEffect( () => {
    fetchUser();
  }, [] );


  const fetchUser = () => {
    setWorking( true );
    getUser( ( err, res ) => {
      setWorking( false );
    });
  };  


  const handleLogout = () => {
    HandleLogout();
  }




  return (
      <div className="header-container">
        <div className="header">
            <div className="header-logo" onClick={ () => navigate( '/' ) }><MdTaskAlt /> Property Managers List</div>
            <div className="header-nav">
                <span className="me-1"><Link to="/sites">Sites</Link></span>
                <span className="me-1"><Link to="/rfps">RFPs</Link></span>
                <span className="me-1"><Link to="/orgs?isManager=true">Managers</Link></span>
                <span className="me-1"><Link to="/orgs?isOwner=true">Owners</Link></span>
                <span className="me-1"><Link to="/props">Props</Link></span>
                {user.id === -1 &&
                  <>
                    <a href={process.env.REACT_APP_API_HOST + 'users/login/google'}>Sign in with Google</a>
                    <span className="me-1 ms-2"><Link to="/login"><Button outline size="sm" color="secondary">Sign in</Button></Link></span>
                  </>
                }
                {user.id !== -1 &&
                  <>
                    {user.username}
                    {' '}
                    {user.orgName}
                    <span className="me-1 ms-2"><Button outline color="secondary" size="sm" onClick={handleLogout}>Sign off</Button></span>
                  </>
                }
            </div>
        </div>
      </div>
  );
}


export default Header;
