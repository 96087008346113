import { createStore } from 'redux';


function reducer( state, action ) {
    switch ( action.type ) {
        case 'SET_USER':
            return { ...state, user: {...action.payload} };
        default:
            return state;
    }
}


const initialState = { user: { id: -1, username: '' } };


const store = createStore( reducer, initialState );
export default store;
