import axios from 'axios';
import _ from 'lodash';
import store from './store';


function resetSession() {
    if( store.getState().user.id !== -1 ) store.dispatch({ type: 'SET_USER', payload: { id: -1, username: '' } });
}


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    withCredentials: true
});


axiosInstance.interceptors.response.use(
    res => {
        return res;
    },
    err => {
        if( err?.response?.status === 403 ) {
            if( window.location.pathname !== '/login' ) window.location = '/login';
            return;
        }
        return Promise.reject( err );
    }
);




export function Register( orgName, orgZip, orgIsManager, orgIsOwner, email, username, password, confirm, callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'users/register', { orgName, orgZip, orgIsManager, orgIsOwner, email, username, password, confirm } )
    .then( res => {
        store.dispatch({ type: 'SET_USER', payload: {...res.data} });
        if( typeof callback === 'function' ) callback( null, res.data );
        //const persons = res.data;
        //this.setState({ persons });
    })
    .catch( err => {
        console.log( err );
        if( err.response.status === 400 ) {
            callback( err.response.data, null );
        }
    });
}




export function getUser( callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'users/me', {} )
    .then( res => {
        store.dispatch({ type: 'SET_USER', payload: {...res.data} });
        if( typeof callback === 'function' ) callback( null, res.data );
        //const persons = res.data;
        //this.setState({ persons });
    })
    .catch( err => {
        if( _.has( err, 'response.status' ) && err.response.status === 403 ) {
            resetSession();
        } else {
            resetSession();
        }
    });
}


export function Login( username, password, callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'users/login', { username, password } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res.data );
        //const persons = res.data;
        //this.setState({ persons });
    })
    .catch( err => {
        callback( err, null );
    });
}


export function Logout( callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'users/logout', {} )
    .then( res => {
        resetSession();
        if( typeof callback === 'function' ) callback( null, res.data );
        window.location.href = '/';
    });
}




export function patchUser( username, kvps, callback ) {
    axiosInstance.patch( process.env.REACT_APP_API_HOST + 'users/' + username, kvps )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res.data );
    })
    .catch( err => {
        callback( err, null );
        /*if( err.response.status === 400 ) {
            callback( err.response.data, null );
        }*/
    });
}




// Get list of users
export function getUsers( q, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'users?' + q )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        callback( err, null );
        /*if( err.response.status === 400 ) {
            callback( err.response.data, null );
        }*/
    });
}


export function createCalendar( calendar, callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'calendars', calendar )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        if( typeof callback === 'function' ) callback( err, null );
    });
}
