// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: auto;
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .calendar-title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 10px 0;
    background-color: #f4f4f4;
    border-bottom: 1px solid #ccc;
  }
  
  .calendar-header {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    background-color: #f4f4f4;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    text-align: center;
    font-weight: bold;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1px;
  }
  
  .day-header {
    text-align: center;
  }
  
  .calendar-day {
    padding: 10px;
    background-color: #fff;
    text-align: center;
    border: 1px solid #e0e0e0;
    height: 90px;
  }
  
  .calendar-empty {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/header/calendar/Calendar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,8BAA8B;IAC9B,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;IACf,yBAAyB;IACzB,6BAA6B;EAC/B;;EAEA;IACE,aAAa;IACb,qCAAqC;IACrC,yBAAyB;IACzB,eAAe;IACf,6BAA6B;IAC7B,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,qCAAqC;IACrC,QAAQ;EACV;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,yBAAyB;EAC3B","sourcesContent":[".calendar {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    max-width: 800px;\n    margin: auto;\n    font-family: Arial, sans-serif;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    overflow: hidden;\n  }\n  \n  .calendar-title {\n    text-align: center;\n    font-size: 1.5rem;\n    font-weight: bold;\n    padding: 10px 0;\n    background-color: #f4f4f4;\n    border-bottom: 1px solid #ccc;\n  }\n  \n  .calendar-header {\n    display: grid;\n    grid-template-columns: repeat(7, 1fr);\n    background-color: #f4f4f4;\n    padding: 10px 0;\n    border-bottom: 1px solid #ccc;\n    text-align: center;\n    font-weight: bold;\n  }\n  \n  .calendar-grid {\n    display: grid;\n    grid-template-columns: repeat(7, 1fr);\n    gap: 1px;\n  }\n  \n  .day-header {\n    text-align: center;\n  }\n  \n  .calendar-day {\n    padding: 10px;\n    background-color: #fff;\n    text-align: center;\n    border: 1px solid #e0e0e0;\n    height: 90px;\n  }\n  \n  .calendar-empty {\n    background-color: #f9f9f9;\n    border: 1px solid #e0e0e0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
