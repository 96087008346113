import React from 'react';
import './Calendar.css';
import { MdAdd, MdArrowLeft, MdArrowRight, MdPlusOne, MdRsvp } from 'react-icons/md';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const CalendarMonth = ({ year, month, setYear, setMonth }) => {
  const getDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const getEmptyDaysBeforeStart = (startDay) => {
    return Array.from({ length: startDay }, (_, i) => null);
  };

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const daysInMonth = getDaysInMonth(year, month);
  const firstDay = daysInMonth[0].getDay();

  // Fill the grid
  const emptyDays = getEmptyDaysBeforeStart(firstDay);
  const calendarDays = [...emptyDays, ...daysInMonth];

  return (
    <div className="calendar">
      <div className="calendar-title">
        <Button onClick={ () => month === 0 ? ( setYear( year - 1 ), setMonth( 11 ) ) : setMonth( month - 1 ) }><MdArrowLeft /></Button>
        {monthNames[month]} {year}
        <Button onClick={ () => month === 11 ? ( setYear( year + 1 ), setMonth( 0 ) ) : setMonth( month + 1 ) }><MdArrowRight /></Button>
      </div>
      <div className="calendar-header">
        {daysOfWeek.map((day, index) => (
          <div key={index} className="day-header">
            {day}
          </div>
        ))}
      </div>
      <div className="calendar-grid">
        {calendarDays.map((date, index) =>
          date ? (
            <div key={index} className="calendar-day">
                <div>
                    {date.getDate()}
                    <Button size="sm" outline color="primary"><MdAdd /></Button>
                </div>
                <div>
                </div>
            </div>
          ) : (
            <div key={index} className="calendar-empty"></div>
          )
        )}
      </div>
    </div>
  );
};

export default CalendarMonth;
