import { useEffect, useState } from "react";
import MonthCalendar from "./month"
import { Input } from "reactstrap";
import { useParams } from "react-router-dom";
import { createCalendar } from "../../../actions";

const Calendar = () => {

    const [ year, setYear ] = useState( 2024 );
    const [ month, setMonth ] = useState( 10 );
    const [ name, setName ] = useState( '' );

    const { calendarId } = useParams();
    console.log( 'calendarId: ', calendarId );

    useEffect( () => {
        if( calendarId === undefined ) handleCreateCalendar();
    }, [] );

    const handleCreateCalendar = () => {
        createCalendar( {}, ( err, res ) => {
            console.log( err, res );
        });
    };

    const handleCreateActivity = () => {

    };

    return(
        <>
            <Input />
            <MonthCalendar 
                year={year} 
                month={month} 
                setYear={setYear} 
                setMonth={setMonth} 
                createActivity={handleCreateActivity}
            />
        </>
    );
}

export default Calendar;