import Calendar from '../../components/header/calendar';
import Header from './../../components/header';
import { useSelector } from 'react-redux';


function Home() {
  const user = useSelector( state => state.user );
  return (
    <div className="App">

      <Header />
      <Calendar />
    </div>
  );
}


export default Home;
