import React from 'react';


import { Container, Row, Col, Card, CardBody, Form, Input, Label, InputGroup, FormGroup, Button } from 'reactstrap';


import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


import { getUser, Login as HandleLogin } from '../../actions';


import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BsFacebook, BsLinkedin, BsTwitter } from 'react-icons/bs';




class LoginForm  extends React.Component {
  constructor( props ){
    super( props );
    this.state = {
        username: '',
        password: '',
        error: '',
        email: '',
    };
    this.handleLogin = this.handleLogin.bind( this );
    this.refresh = this.refresh.bind( this );
    this.animationInstance = null;
  }


  componentDidMount() {
    this.refresh();
  }


  refresh() {
    getUser();
  }


  handleLogin() {
    this.setState({ error: '', }, () => {
        HandleLogin( this.state.username, this.state.password, ( err, user ) => {
            console.log( err, user );
            if( err ) {
                return this.setState({ error: 'Invalid login' });
            }
            this.props.dispatch({ type: 'SET_USER', payload: user });
        });    
    });
  }


  render() {
    return (
     
        <Form>
           
            <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                <h3>Sign in with</h3>
                <button type="button" class="shadow ms-5 rounded-circle btn btn-primary btn-floating mx-1">
                    <BsFacebook />
                </button>


                <button type="button" class="shadow rounded-circle btn btn-primary btn-floating mx-1">
                    <BsTwitter />
                </button>


                <button type="button" class="shadow rounded-circle btn btn-primary btn-floating mx-1">
                    <BsLinkedin />
                </button>
            </div>


            <div class="divider d-flex align-items-center my-4">
                <p class="text-center fw-bold mx-3 mb-0">Or</p>
            </div>


            <div class="form-outline mb-4 mt-4">
                <Input
                    type="username"
                    placeholder="username"
                    onChange={ e => this.setState({ username: e.target.value }) }
                />
            </div>


            <div class="form-outline mb-3">
                <Input
                    type="password"
                    placeholder="Password"
                    onKeyPress={ e => { if( e.key === 'Enter' ) this.handleLogin(); } }
                    onChange={ e => this.setState({ password: e.target.value }) }
                />
            </div>


            {/*<div class="d-flex justify-content-between align-items-center">
                <div class="form-check mb-0">
                <input class="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
                <label class="form-check-label" for="form2Example3">
                    Remember me
                </label>
                </div>
            </div>*/}


            {this.state.error !== '' && <div class="clearfix text-lg-start mt-4 mb-4 pt-2">
                {this.state.error}
            </div>}


            <div class="clearfix text-lg-start mt-4 mb-4 pt-2">
                <a className="float-start" href="#">Forgot password?</a>
                <Button
                    className="float-end"
                    type="button"
                    color="primary"
                    style={{paddingLeft: '2.5rem', paddingRight: '2.5rem'}}
                    onClick={this.handleLogin}
                >
                    Login
                </Button>
            </div>


            <hr />


            <p class="small mt-4 pt-1 mb-0 text-center">
                Don't have an account? <br />
                <Button onClick={ () => this.props.setMode( 'register' ) } type="button" color="primary" style={{paddingLeft: '2.5rem', paddingRight: '2.5rem'}}>Create a Free Account</Button>
            </p>
        </Form>
   


    );


  }
}




const mapStateToProps = ( state, ownProps ) => {
    return { user: state.user };
};


const mapDispatchToProps = dispatch => ({
    dispatch
});


export const withRouter = (Component) => {
    const Wrapper = (props) => {
      const navigate = useNavigate();
     
      return (
        <Component
          navigate={navigate}
          {...props}
          />
      );
    };
   
    return Wrapper;
  };


export default connect( mapStateToProps, mapDispatchToProps )( withRouter( LoginForm ) );
