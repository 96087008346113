import React from 'react';
import './App.css';
import store from './store';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/home';
import Login from './pages/login';


function App() {


  const domain = window.location.hostname;
  console.log( 'App.js', domain );


  return (
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            {domain === 'ouractivities.app' || domain === 'www.ouractivities.app' &&
              <>
                <Route path="/" element={<Home />} />
                <Route path="/:calendarId" element={<Home />} />
                <Route path="/login" element={<Login />} />
              </>
            }
            {domain !== 'ouractivities.app' &&
              <>
                {/*<Route path="/:path?/:subPath?" element={<Site />} />*/}
              </>
            }
          </Routes>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
}


export default App;
